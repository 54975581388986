.datePicker input{
    border: none;
    border-radius: 0.5rem/* 8px */;
    background-color: rgb(249 250 251) !important; /*bg-gray-100*/
    color: rgb(17 24 39);
    outline: none;
}
.datePicker input::placeholder{
    color: rgb(156 163 175);
}
.datePicker input:hover{
    cursor: pointer;
    background-color: rgb(229 231 235);
}