@tailwind base;
@tailwind components;
@tailwind utilities;
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-gantt/styles/material.css';

body{
    color: rgb(63 63 70);
    font-family: Inter, sans-serif;
    overflow-x: hidden;
}
.vertical-scrollbar::-webkit-scrollbar {
    width: 6px;
}

.vertical-scrollbar::-webkit-scrollbar-track {
    background: rgba(112, 128, 144, 0.161);
}

.vertical-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(112, 128, 144, 0.4); 
}

.horizontal-scrollbar::-webkit-scrollbar {
    height: 6px;
}

.horizontal-scrollbar::-webkit-scrollbar-track {
    background: rgba(112, 128, 144, 0.161);
}

.horizontal-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(112, 128, 144, 0.4); 
}

.chat-scrollbar::-webkit-scrollbar {
    width: 6px;
}

.chat-scrollbar::-webkit-scrollbar-track {
    background: #00000000;
}

.chat-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(112, 128, 144, 0.2); 
}